import { initApp } from 'aa/vue/App';
import SignUpExplainer from 'aa/vue/models/steps/SignUpExplainer';
import SignUp from 'vue/models/steps/SignUp';
import AgeGate from 'aa/vue/models/steps/AgeGate';
import 'helpers/tracking.js';
import 'aa/components/FooterAllAccess';
import 'aa/components/accessibilityAllAccess.js';

initApp({
  steps: [
    new SignUpExplainer(),
    new SignUp(),
  ],
  nonFlowRoutes: CBS.Registry.Features?.age_gate_enabled === true ? [new AgeGate()] : []
});
