import AASignUp from 'aa/vue/models/steps/SignUp'

export default class SignUp extends AASignUp {
    checkFlowRedirect(from) {
        if (from.name !== 'sign_up_explainer') {
            return {
                name: 'sign_up_explainer'
            }
        }

        return null
    }
}
